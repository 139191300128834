import React from "react";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import styled from "styled-components";

export const StyledPaper = styled.div`
  background: url('/assets/images/banner_1.png'); 
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding: 48px 24px;
  padding-top: 24px;
`

export const StyledBox = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;
  background: url('assets/images/about_box.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 80px 100px;
  padding-bottom: 100px;
  color: #081A32;

  @media screen and (max-width: 800px) {
    & {
      background: url('assets/images/mobile_box.png');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 80px 50px;
    }
  }
` 

export const StyledTitle = styled.div`
  font-size: 48px;
  font-family: VT323; 
  margin-bottom: 20px;
  text-align: center;
`

export const StyledDragon = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
`

export const StyledDragonBox = styled.div`
  padding: 10px;
  display: flex;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`

export const StyledDragonDescription = styled.div`
  color: black;
  padding: 10px;
`

export const DescriptionTitle = styled.div`
  font-size: 13px;
  font-family: VT323;
  margin-bottom: 5px;
`

export const Description = styled.div`
  font-size: 13px;
`
export const GradientBg = styled(Box)`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100vw;
  max-height: 700px;
  object-fit: cover;
`

export default function About() {

  return (
    <StyledPaper id="about">
      <Container sx={{ paddingLeft:"0", paddingRight: "0" }}>
        <div style={{ height: "100px" }}></div>
        <img src="/assets/images/logo.png" alt="DRACOVERSE" style={{ width: "100%", maxWidth:"320px", marginLeft: "auto", marginRight: "auto", display: "flex", marginBottom: "20px" }}/>
        <Grid container alignItems="center" >
          <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: "40px" }}>
            <StyledBox>
              <p style={{ marginBottom: "40px" }}>
                Welcome to Dracoverse; a fleet of 1,888 Genesis Draco protecting the Metaverse. Our vision is to expand the Metaverse community by fostering learning and growth amongst our members.
              </p>
              <p>
                $DRA represents the essence of the community, passively distributed to all Genesis Draco holders to breed Baby Draco & summoning of Draco Knight. Behold as we bring you on an expedition in the Dracoverse.
              </p>
              <div style={{ marginLeft:"auto", marginRight:"auto", width:"200px", textAlign: "center", marginTop: "40px", background: "#081A32", padding: "20px" }}>
                <a style={{ color: "white", textDecoration: "none" }} href="https://app.dracoversenft.com/">Enter APP</a>
              </div>
            </StyledBox>
          </Grid>
        </Grid>
      </Container>
    </StyledPaper> 
  );
}