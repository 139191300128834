import React from "react";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import styled from "styled-components";

export const StyledTitle = styled.div`
  font-size: 48px;
  font-family: VT323; 
  margin-bottom: 40px;
  text-align: center;
  padding: 20px;
  padding-bottom: 0;
`;

export const StyledCircle = styled.div`
  background: #FFFFFF;
  border-radius: 40px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  color: #081A32;
  font-size: 20px;
  font-family: VT323;
  align-items: center;
`;

export const StyledRoadmap = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media only screen and (max-width: 576px) {
    margin-bottom: 40px;
  }
`

export const Info = styled.div`
  background: url('/assets/images/mobile_box.png'); 
  background-position: center;
  background-size: 100% 100%;
  border-radius: 5px;
  color: #EC5524;
  padding: 40px;
  max-width: 320px;
  float: right;
  font-size: 18px;
  line-height: 1.3;
`

export const AltInfo = styled.div`
  background: url('/assets/images/mobile_box.png'); 
  background-position: center;
  background-size: 100% 100%;
  border-radius: 5px;
  color: #EC5524;
  padding: 40px;
  max-width: 320px;
  font-size: 18px;
  line-height: 1.3;
`

export const Line = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`

export const StyledDivider = styled(Divider)`
  width: 50%;
  border-color: white !important;
  height: calc( 100% - 45px ) !important;
`

export const Banner = styled.div`
  background: url('/assets/images/banner_2.png'); 
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
`;

export const ExtraRoadmap = styled.div`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
`

export const GradientBg = styled(Box)`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100vw;
  max-height: 800px;
  object-fit: cover;
  margin-top: 204px;
`

export const InfoTitle = styled.div`
  margin-bottom: 10px;
  font-size: 24px;
  color: black;
`

export const Spacer = styled.div`
  flex: 1;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`

export default function Roadmap() {

  return (
    <Banner id="roadmap">
      <div style={{ height: "30px" }}></div>
      <Container style={{ maxWidth: "1000px", marginTop: "50px", marginBottom: "50px" }}>
        <StyledTitle>Roadmap</StyledTitle>
        <StyledRoadmap>
          <div style={{ flex: 1 }}>
            <Info>
              <InfoTitle>Phase 1: Genesis Mint</InfoTitle>
              1,888 Genesis Draco will be released into the metaverse on Ethereum blockchain.<br/><br/>Only 8 Legendary Draco will be animated and brought to life.
            </Info>
          </div>
          <Line>
            <StyledCircle>1</StyledCircle> 
            <StyledDivider orientation="vertical" />
          </Line>
          <Spacer style={{ flex: 1 }}></Spacer>
        </StyledRoadmap>
        <StyledRoadmap>
          <Spacer style={{ flex: 1 }}></Spacer>
          <Line>
            <StyledCircle>2</StyledCircle> 
            <StyledDivider orientation="vertical" />
          </Line>
          <div style={{ flex: 1 }}>
            <AltInfo>
              <InfoTitle>Phase 2: $DRA Staking & Breeding</InfoTitle>
              Each Genesis Draco generates 10 $DRA token daily passively staked. $DRA will be required for breeding and awakening.
              <br/><br/>Introducing to 6,666 Baby Draco with different traits and aesthetics.
              <br/><br/>Staking and breeding will go live during mint.
              <br/><br/>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img alt="DRA" src="/assets/images/dra.png" width="50px"/>
              </div>
            </AltInfo>
          </div>
        </StyledRoadmap>
        <StyledRoadmap>
          <div style={{ flex: 1 }}>
            <Info>
              <InfoTitle>Phase 3: Summoning of Draco Knight</InfoTitle>
              With the enhancement of $DRA essence, Draco Knight can be awakened within each Draco which is also known as their ultimate knight form. Collection supply will be capped at 888.
              <br/><br/>More details will be covered in whitepaper 2.0
            </Info>
          </div>
          <Line>
            <StyledCircle>3</StyledCircle> 
            <StyledDivider orientation="vertical" />
          </Line>
          <Spacer style={{ flex: 1 }}></Spacer>
        </StyledRoadmap>
        <StyledRoadmap>
          <Spacer style={{ flex: 1 }}></Spacer>
          <Line>
            <StyledCircle>4</StyledCircle> 
            <StyledDivider orientation="vertical" />
          </Line>
          <div style={{ flex: 1 }}>
            <AltInfo>
              <InfoTitle>Phase 4: Dracoverse Launchpad</InfoTitle>
              With the aim to foster growth and learning within the community, Dracoverse be providing exclusive analysis on NFT projects as we recognized the importance of passing on knowledge in this space.
              <br/><br/>Partnership benefits will also be made available to Genesis Draco holders such as exclusive whitelist spots.
            </AltInfo>
          </div>
        </StyledRoadmap>
        <StyledRoadmap>
          <div style={{ flex: 1 }}>
            <Info>
              <InfoTitle>Phase 5: Into the Metaverse</InfoTitle>
              The collection will not be limited to 2D NFT arts only, genesis holders will be able to get their hands on the VX collection of Dracoverse NFTs. A new 3D voxel-based avatars will be available to mint and represent holders in the metaverse such as The Sandbox and Worldwide Webb Land.
            </Info>
          </div>
          <Line>
            <StyledCircle>5</StyledCircle> 
            <StyledDivider orientation="vertical" />
          </Line>
          <Spacer style={{ flex: 1 }}></Spacer>
        </StyledRoadmap>
      </Container>
    </Banner>
  );
}