import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import styled from "styled-components";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const StyledAppBar = styled(AppBar)`
  background: #081A32 !important;
  a {
    color: white;
    text-decoration: none;
    text-shadow: 0px 4px 4px #000000;
    margin-right: 16px;
  }

  hr {
    color: #C4C4C4;
    box-shadow: 0px 4px 4px #000000;
    height: 16px;
    margin-right: 16px;
    align-self: center;
    border-color: #C4C4C4;
  }
`

export default function ButtonAppBar() {

  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250, background: "#081A32", height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['About', 'Legendary', 'Roadmap', 'FAQ'].map((text, index) => (
          <ListItem button key={text}>
            <Link href={"#"+text} style={{ textDecoration: "none" }}><ListItemText primary={text} style={{ color: "white" }}/></Link>
          </ListItem>
        ))}
      </List>
      <Box style={{ display: "flex", alignItems: "center"}}>
        <a href="https://twitter.com/DracoverseNFT" target="_blank" rel="noreferrer"><img alt="" src="/assets/images/twitter.svg" height="20px" style={{ marginRight: "20px", marginLeft: "20px" }} /></a>
        <a href="https://discord.gg/dracoverse" target="_blank" rel="noreferrer"><img alt="" src="/assets/images/discord.svg" height="25px" style={{ marginRight: "10px" }} /></a>
        <a href="https://opensea.io/collection/dracoverse" target="_blank" style={{ marginRight: "6px" }} rel="noreferrer"><img alt="" src="/assets/images/opensea.svg" height="45px" /></a>
      </Box>
    </Box>
  );

  return (
      <StyledAppBar position="fixed">
        <SwipeableDrawer
          anchor="left"
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
        <Toolbar>
          <IconButton
            onClick={toggleDrawer(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: ["inline","inline", "none"] }}>
            <MenuIcon />
          </IconButton>
          <Box component="img" src="/assets/images/logo.png" height="32px" alt="" sx={{ mr: 1, filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.8))" }} />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: ["none","none", "flex"], alignItems: "center" }}>
            <Link href="#about">
              About
            </Link>
            <Link href="#legendary">
              Legendary
            </Link>
            <Link href="#roadmap">
              Roadmap
            </Link>
            <Link href="#faq">
              FAQ
            </Link>
            <Divider orientation="vertical" flexItem />
            <a href="https://twitter.com/DracoverseNFT" target="_blank" rel="noreferrer"><img src="/assets/images/twitter.svg" alt="" /></a>
            <a href="https://discord.gg/dracoverse" target="_blank" rel="noreferrer" style={{ marginRight: "6px" }}><img src="/assets/images/discord.svg" alt="" /></a>
            <a href="https://opensea.io/collection/dracoverse" target="_blank" rel="noreferrer" style={{ marginRight: "6px" }}><img src="/assets/images/opensea.svg" alt="" /></a>
          </Box>
          { /*
          <Button style={{ background: "#BE2316", fontFamily: "Genty" }} variant="contained">
            <img style={{ marginRight: "6px", height: "20px" }} src="/assets/images/metamask.png" alt="" />Connect
          </Button>
          */ }
        </Toolbar>
      </StyledAppBar>
  );
}