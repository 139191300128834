import React from "react";
import styled from "styled-components";
import bg from "./assets/images/background.png";
import ButtonAppBar from "./components/ButtonAppBar";
import MintBanner from "./components/MintBanner";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Faq from "./components/Faq";
import Legendary from "./components/Legendary";

function App() {

  return (
    <div>
      <ButtonAppBar />
      <About />
      <Legendary />
      <Roadmap /> 
      <Faq />   
    </div>
  );
}

export default App;
