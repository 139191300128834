import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import styled from "styled-components";
import BigNumber from 'bignumber.js';
import LinearProgress from '@mui/material/LinearProgress';

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 150px;

  & > img {
    max-width: 300px;
    border-radius: 200px;
  }
`;

export const StyledBox = styled.div`
  background: url('/assets/images/minter.png'); 
  background-repeat: no-repeat;
  height: 440px;
  text-align: center;
  background-size: contain;
  background-position: center;
  padding: 60px;

  & > div {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    font-family: Genty;
    color: #633F5C;
    margin-bottom: 15px;
  }
`;

export const BalanceBox = styled.div`
  background: linear-gradient(0deg, #823B00 -0.29%, #692705 100.59%);
  opacity: 0.75;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  & > div {
    color: white;
    text-shadow: 0px 4px 4px #000000;
    padding: 16px 10px;
  }
`

export const StyledDragon = styled.img`
  height: 200px;
`

export const StyledButton = styled(Button)`
  font-family: Genty !important; 
  padding: 6px 12px;
  background: linear-gradient(0deg, #823B00 -0.29%, #692705 100.59%);
  opacity: 0.75;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  color: white;
  text-shadow: 0px 4px 4px #000000;
  font-size: 12px;
`

export const StyledLinearProgress = styled(LinearProgress)`
  background: linear-gradient(0deg, #823B00 -0.29%, #692705 100.59%);
  opacity: 0.75;
  border-radius: 10px;
`

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
`

export const Frame = styled.iframe`
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
`

export default function MintBanner() {

  return (
    <Banner>
            { /*
            <Frame 
            src="https://www.youtube.com/embed/tI1iKFDSAhc?controls=0&autoplay=1&loop=1&playlist=tI1iKFDSAhc">
            </Frame>
            */ }
            <img src="/assets/images/random.gif" />
          { /*
          <Grid item xs={12} sm={12} md={6} sx={{ display: ["none","none","block"] }}>
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ textAlign: "right"}}>
                <StyledDragon src="/assets/images/fire_dragon.png" />
              </Grid>
              <Grid item xs={6}>
                <StyledDragon src="/assets/images/rock_dragon.png" />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right"}}>
                <StyledDragon src="/assets/images/water_dragon.png" />
              </Grid>
              <Grid item xs={6}>
                <StyledDragon src="/assets/images/ice_dragon.png" />
              </Grid>
            </Grid>
          </Grid>
          */}
    </Banner> 
  );
}