import React, { useState } from "react";
import Container from '@mui/material/Container';
import styled from "styled-components";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const StyledTitle = styled.div`
  font-size: 48px;
  font-family: VT323; 
  margin-bottom: 40px;
  text-align: center;
  padding: 20px;
  padding-bottom: 0;
`

export const StyledBoxTitle = styled.div`
  font-size: 24px;
  font-family: VT323;
  padding: 5px;
  padding-bottom: 0;
`

export const StyledCard = styled.div`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  color: black;
  margin-bottom: 30px;
`

export const Description = styled.div`
  padding: 5px 8px;
  padding-top: 0;
  margin-top: -15px;
  color: #EC5524;
` 

export default function Faq() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div id="faq">
      <div style={{ height: "10px" }}></div>
      <Container style={{ maxWidth: "850px", marginTop: "50px", marginBottom: "80px" }}>
        <StyledTitle>Frequently Asked Questions</StyledTitle>
        <Accordion sx={{ marginBottom: "20px" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <StyledBoxTitle>How much does it cost to mint genesis Draco?</StyledBoxTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Description>
              The cost of minting one Genesis Draco is 0.055 ETH + gas fees.
            </Description>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginBottom: "20px" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <StyledBoxTitle>How much does it cost to mint baby Draco?</StyledBoxTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Description>
              It requires 2 Genesis Draco in the user’s ETH wallet and 450 $DRA reward tokens + gas fees.
            </Description>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginBottom: "20px" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <StyledBoxTitle>What is the supply size of both collections?</StyledBoxTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Description>
              <p>Genesis Draco: 1,888</p>
              <p>Baby Draco: 6,666</p>
            </Description>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginBottom: "20px" }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <StyledBoxTitle>When will sale begin?</StyledBoxTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Description>
              <p>Pre-sale: Feb 4 2022, 10:30am EST</p>
              <p>Public sale: Feb 4 2022, 10:30pm EST</p>
            </Description>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginBottom: "20px" }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <StyledBoxTitle>When will staking & breeding go live?</StyledBoxTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Description>
              <p>Staking & breeding will go live shortly once pre-sale starts.</p>
            </Description>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ marginBottom: "20px" }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <StyledBoxTitle>How many legendary traits will there be?</StyledBoxTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Description>
              <p>8 animated legendary Draco exist in the collection</p>
            </Description>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div> 
  );
}