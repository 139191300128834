import React from "react";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import styled from "styled-components";

export const Banner = styled.div`
  width: 100%;
  padding-top: 60px;
  margin-top: 110px;
`

export const StyledTitle = styled.div`
  font-size: 48px;
  font-family: VT323; 
  margin-bottom: 40px;
  text-align: center;
`

export const Description = styled.div`
  line-height: 1.5;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

export default function About() {

  return (
    <div id="legendary">
      <div style={{ height: "100px" }}></div>
      <Container style={{ marginTop: "0px", paddingBottom: "100px" }}>
        <StyledTitle>Legendary</StyledTitle>
        <Grid container spacing={4}>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/zombie.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Zombified Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/shen.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Shen Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/radioactive.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Radioactive Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/chrome.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Neo Chrome Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/meka.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Meka Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/ancient.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Ancient Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/dark.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Dark Draco</div>
          </Grid>
          <Grid xs={6} sm={4} md={3} item>
            <img width="100%" src="/assets/images/golden.gif" alt="" />
            <div style={{ textAlign: "center", marginTop: "10px" }}>Golden Draco</div>
          </Grid>
        </Grid>
      </Container>
    </div> 
  );
}